<script>
import Layout from "@/router/layouts/donators";
import { api } from "@/state/services";
import axios from 'axios';

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      donator: null,

      type: "",

      files: null,

      file: {
        entity: null,
        withdrawal: null,
      },
    };
  },
  methods: {
    getDonator() {
      api.get("donators").then((response) => {
        if (response.data.status == "success") {
          this.donator = response.data.donator;

          if (response.data.donator.status == "approved") {
            this.$router.push("/");
          }
        }
      });
    },
    getFiles() {
      api.get("donators/files").then((response) => {
        if (response.data.status == "success") {
          this.files = response.data.list;
        }
      });
    },
    setEntity() {
      let formData = new FormData();

      formData.append("file", this.file.entity);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(process.env.VUE_APP_URL_API + "donators/files/entity", formData, config)
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message);
            this.getFiles();
          } else {
            this.$noty.error(response.data.message);
          }
        });
    },
    setWithdrawal() {
      let formData = new FormData();

      formData.append("file", this.file.withdrawal);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(process.env.VUE_APP_URL_API + "donators/files/withdrawal", formData, config)
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message);
            this.getFiles();
          } else {
            this.$noty.error(response.data.message);
          }
        });
    },
    selectEntity() {
      this.file.entity = this.$refs.entity.files[0];
      this.setEntity();
    },
    selectWithdrawal() {
      this.file.withdrawal = this.$refs.withdrawal.files[0];
      this.setWithdrawal();
    },
  },
  mounted() {
    this.getDonator();
    this.getFiles();
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-lg-8 col-xl-6">
        <div class="card">
          <div class="card-body p-4">
            <h5 class="mb-4">
              Siga os passos abaixo para validar a sua conta de doador.
            </h5>
            <p>
              1º – Acesse o site na
              <strong>Nota Fiscal Paulista</strong> através do link<br />
              <a href="https://www.nfp.fazenda.sp.gov.br/"
                >https://www.nfp.fazenda.sp.gov.br/
                <i class="bx bx-link-external align-middle"></i
              ></a>
              <img class="w-100" src="@/assets/images/nfp-1.png" />
            </p>
            <p>
              2º – Se você ainda não possui um cadastro na
              <strong>Nota Fiscal Paulista</strong> clique em
              <strong>Cadastro Pessoa Física</strong>
            </p>
            <p>
              3º – Informe seu <strong>CPF e Senha</strong> para acessar o
              sistema da <strong>Nota Fiscal Paulista</strong><br />
              <a href="https://www.nfp.fazenda.sp.gov.br/"
                >https://www.nfp.fazenda.sp.gov.br/
                <i class="bx bx-link-external align-middle"></i
              ></a>
            </p>
            <p>
              4º – Após o acesso ao sistema da
              <strong>Nota Fiscal Paulista</strong> você deve realizar a
              indicação da instituição, pelo CNPJ
              <strong>03.608.862/0001-90</strong> ou pelo nome
              <strong>INSTITUTO DE INCENTIVO A VIDA</strong> através da página
              <strong>Entidades > Doação de Cupons com CPF</strong><br />
              <a
                href="https://www.nfp.fazenda.sp.gov.br/EntidadesFilantropicas/DoacaoAutomatica.aspx"
                >https://www.nfp.fazenda.sp.gov.br/EntidadesFilantropicas/DoacaoAutomatica.aspx
                <i class="bx bx-link-external align-middle"></i
              ></a>
            </p>
            <p>
              5º – Após realizar a indicação, será necessário o envio do
              <strong>Print de Tela</strong> para comprovação da indicação como
              demostrado abaixo:
              <img class="w-100" src="@/assets/images/nfp-2.png" />
            </p>
            <p>
              <template v-if="files">
                <template v-if="files.entity && files.entity.status == 'waiting'">
                  <input
                    type="file"
                    ref="entity"
                    @change="selectEntity"
                    aria-label="Entidade"
                    class="form-control"
                  />
                </template>
                <template v-else-if="files.entity && files.entity.status == 'pending'">
                  <a class="btn btn-default align-items-center" target="_blank" :href="files.entity.url"> Abrir Arquivo <i class="bx bx-link-external align-middle"></i></a>
                  <div class="mt-2 text-danger">Este arquivo está aguardando aprovação.</div>
                </template>
                <template v-else-if="files.entity && files.entity.status == 'approved'">
                  <a class="btn btn-default align-items-center" target="_blank" :href="files.entity.url"> Abrir Arquivo <i class="bx bx-link-external align-middle"></i></a>
                  <div class="mt-2 text-success">O arquivo foi aprovado.</div>
                </template>
                <template v-else-if="files.entity && files.entity.status == 'repproved'">
                  <input
                    type="file"
                    ref="entity"
                    @change="selectEntity"
                    aria-label="Entidade"
                    class="form-control"
                  />
                  <div class="mt-2 text-warning">O arquivo foi reprovado, envie corretamente o Print da Tela.</div>
                </template>
              </template>
            </p>
            <p>
              6º – Para efetivar a sua doação de cupons com CPF de forma
              automática é necessário que você tenha feito pelo menos uma
              transferência (saque) e enviar o
              <strong>Print de Tela</strong> como demostrado abaixo:
              <img class="w-100" src="@/assets/images/nfp-3.png" />
            </p>
            <p>
              <template v-if="files">
                <template v-if="files.withdrawal && files.withdrawal.status == 'waiting'">
                  <input
                    type="file"
                    ref="withdrawal"
                    @change="selectWithdrawal"
                    aria-label="Saque"
                    class="form-control"
                  />
                </template>
                <template v-else-if="files.withdrawal && files.withdrawal.status == 'pending'">
                  <a class="btn btn-default align-items-center" target="_blank" :href="files.withdrawal.url"> Abrir Arquivo <i class="bx bx-link-external align-middle"></i></a>
                  <div class="mt-2 text-danger">Este arquivo está aguardando aprovação.</div>
                </template>
                <template v-else-if="files.withdrawal && files.withdrawal.status == 'approved'">
                  <a class="btn btn-default align-items-center" target="_blank" :href="files.withdrawal.url"> Abrir Arquivo <i class="bx bx-link-external align-middle"></i></a>
                  <div class="mt-2 text-success">O arquivo foi aprovado.</div>
                </template>
                <template v-else-if="files.withdrawal && files.withdrawal.status == 'repproved'">
                  <input
                    type="file"
                    ref="withdrawal"
                    @change="selectWithdrawal"
                    aria-label="Entidade"
                    class="form-control"
                  />
                  <div class="mt-2 text-warning">O arquivo foi reprovado, envie corretamente o Print da Tela.</div>
                </template>
              </template>
            </p>
            <div v-if="files.withdrawal && files.withdrawal.status == 'pending' && files.withdrawal.status == 'pending'">
              <div class="bg-warning rounded p-3">
                Você já enviou os <strong>Prints de Tela</strong>, aguarde a validação e liberação da sua conta.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
